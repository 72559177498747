@media (max-width: 549px) {
    .filter {
        margin-right: 4%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 20px;
    }
    .sort-label {
        display: none;
    }
    .filter-simple {
        display: inline-block;
        margin-right: 20px;
        width: 100%;
    }
}
@media (min-width: 550px) {
    .filter {
        margin-right: 4%;
        display: inline-block;
        vertical-align:top;
        margin-bottom: 20px;
    }
    tags {
        margin-bottom: 20px;
    }
    .sort-label {
        display: inline;
    }
    .filter-simple {
        display: inline-block;
        margin-right: 20px;
        width: 22%;
    }
}
@media (min-width: 1440px) {
    .taggle {
        margin-right: 100px !important;
    }
    .filterfields {
        position: absolute;
        margin-left: -230px;
        width: 100vw;
        margin-top: -5px;
    }
    .menu-button {
        display: none;
    }
    .filter {
        max-width: 200px;
        display: block;
        position: relative;
        overflow: hidden;
    }
    #tags {
        width: 100%;
    }
    .filter-simple {
        display: block;
    }
}

/* select-filter style like radius and sort */
.select-filter {
    height: auto;
    padding: 0;
}
.select-filter:focus, .select-filter-min:focus, .select-filter, .select-filter-min {
    border: none;
}
.select-filter-min {
    -webkit-appearance: menulist-text;
    -moz-appearance: menulist-text;
    margin-top: -7px;
    font-weight: bold;
    background-color: inherit;
}

/* tagging filter style */
span.taggle_placeholder {
    width: 100%;
    left: 0;
    top: 0;
}
input.taggle_input[type="text"] {
    border: none;
    padding-left: 0;
    width: 100% !important;
}
input.taggle_input[type="text"]:focus {
    border: none;
}
button.close {
    color: red !important;
    font-size: 3rem;
    position: absolute;
    top: 5px;
    right: 3px;
    text-decoration: none;
    padding-left: 2px;
    padding-top: 3px;
    line-height: 75%;
    padding-bottom: 4px;
    display: none;
    border: 0;
    background: 0 0;
    cursor: pointer;
    padding: 0;
    font-weight: bold;
}
#tags {
    /*border-radius: 0;
    border: 1px solid #ADADAD;
    padding: 8px;*/
    color: #555;
    transition: all .25s;
    cursor: text;
    position: relative;
    overflow: hidden;
    width: 100%;
}
.taggle_text {
    text-overflow: ellipsis;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    float: left;
}
.tag-list li {
    display: inline;
    padding-right: 10px;
    float: left;
    margin-bottom: 0.3em;
}
.clear-float {
    clear: both;
}
.tag-list-title {
    margin: .67em 0;
}
.tag-list {
    margin-bottom: 0;
}
.taggle {
    background: #808080 !important;
    color: white;
}
.taggle.applied {
    background: #2ECC40 !important;
    color: white;
}
.tag-list .taggle {
    margin-right: 8px;
    background: #e2e1df;
    padding: 5px 10px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.filter-simple {
    max-width: 200px;
    margin-bottom: 10px;
}
.filter-simple label {
    margin-bottom: 10px;
    margin-top: 0;
}
.filter label {
    margin-bottom: 10px;
}
.slider {
    margin: 0 5px;
    height: 10px;
}