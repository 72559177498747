@media (max-width: 549px) {
    .mobile-logo {
        width: 100px;
        float: none;
        margin: 5px;
    }
    .desktop-logo {
        display: none;
    }
    .mobile-navigation-bar {
        /* box-sizing: border-box;*/
        top: 0;
        height: 50px;
        text-align: center;
        margin-bottom: 5%;
    }
    .mobile-navigation-bar-content {
        padding-top: 10px;
    }
    .menu-button-desktop {
        display: none;
    }
    .menu-button-mobile {
        width: 30px;
        font-size: 18px;
        background-image: url(../img/menu.svg);
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        float: left;
        margin: 5px;
    }
    .search-button-mobile {
        width: 30px;
        font-size: 18px;
        background-image: url(../img/search.svg);
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        float: right;
        margin: 5px;
    }
    .search-button {
        background: #2196f3;
        width: 100%;
        color: white;
    }
    .search-button-main {
        position: relative;
    }
    .search-button:hover {
        background: #1976d2;
    }
    .search-button:before {
        color: white;
        content: "Suche";
    }
    .radius-field {
        width: 25%;
    }
    .location-field {
        width: 75%;
    }
}
@media (min-width: 550px) {
    .mobile-navigation-bar {
        display: none;
    }
    .search-button {
        background-image: url(../img/search_white.svg);
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 1.5rem;
    }
    .search-button:before {
        content: "";
    }
    #location, #query, #radius {
        margin-bottom: 1.5rem;
    }
    /* valign the searchbox at the top of search result list */

    .valign-bottom {
        display: table;
    }
    .valign-bottom .columns {
        display: table-cell;
        vertical-align: bottom;
    }
    .valign-bottom .columns {
        float: none;
        padding-left: 1%;
    }
    .valign-bottom [class*="column"]:first-child {
        padding-left: 0;
    }
    /*.valign-bottom [class*="column"]:nth-child(2) {*/
        /*padding-left: 4%;*/
    /*}*/
    .menu-button {
        width: 30px;
        font-size: 18px;
        background-image: url(../img/menu.svg);
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        float: left;
        margin: 5px;
        margin-bottom: 1.5rem;
    }
    .radius-field {
        width: 40%;
    }
    .location-field {
        width: 60%;
    }
    .columns {
        margin-left: 1%;
    }
}
@media (min-width: 1024px) {
    .radius-field {
        width: 30%;
    }
    .location-field {
        width: 70%;
    }
}
label {
    color: #d3d3d3;
}
.location-results {
    position: absolute;
    border: 1px solid black;
}
.zero-height {
    height: 0;
}
#radius {
    border-left-style: none;
    width: 100%;
}
.radius-field {
    padding-left: 0 !important;
    float: right;
}
.radius-field > select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.location-field {
    float: left;
}
.location-field > .react-autosuggest__container > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-input {
    background: url(../img/search_gray.svg) no-repeat scroll 7px 7px;
    padding-left: 35px !important;
}
.search-box-home {
    max-width: 680px;
    margin: 0 auto;
}
.searchbox > form {
    margin-bottom: 0;
}
.searchbox {
    padding: 1.5rem 0 0.4rem 0;
}
.searchbox-content {
    padding: 1.5rem 0 0.4rem 0;
}
.search-button {
    vertical-align: bottom;
}
.search-button-home {
    background-color: #2196f3;
    color: white;
    font-size: inherit;
    letter-spacing: inherit;
    text-transform: none;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: center;
}
.search-button-home:hover {
    background-color: #1976d2;
    color: white;
}