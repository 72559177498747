.result-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    margin-bottom: 20px;
    padding: 10px;
}
.result-item-image-wrap {
    margin-left: 30px;
    text-align: center;
    line-height: 90px;
}
.result-item-image {
    max-width: 80px;
    max-height: 80px;
    vertical-align: middle;
    overflow: hidden;
}
.result-item-title {
    font-size: 16px;
    color: #00c;
    font-weight: bold;
}
.result-item-details {
    font-family: arial,sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.result-item-advertiser {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6c6c6c;
    font-weight: bold;
}
.result-item-advertiser.text {
    max-width: 75%;
    float: left;
}
.result-item-additional-information {
    line-height: 2;
    font-size: 10pt;
}
.result-item-startdate {
    color: #808080;
    float: left;
    margin-right: 8px;
}
.result-item-tags {
    overflow: hidden;
    text-overflow: ellipsis;
}
.result-item-tag {
    display: inline;
    margin-right: 8px;
    cursor: pointer;
    color: #3c32be;
}
.result-item-tag.location {
    color: #39b54a;
}
.result-item-description {
    line-height: 1.4;
    font-size: 10pt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
}
#hits {
    /*float: right;
    color: #d3d3d3;
  text-size: 20px;
  font-weight: 600;*/
}
#pagination {
    text-align: center;
    font-size: x-large;
}
#pagination ul li {
    display: inline;
}
#pagination ul li a {
    color: black;
}
#pagination ul li + li {
    padding-left: 10px;
}
.pagination-new {
    font-size: medium !important;
}
.bold {
    font-weight: bold;
    color: black;
}
.arrow {
    font-size: 35px;
    text-decoration: none;
    padding: 15px;
}
@media (max-width: 549px) {
    .hits-desc {
        display: none;
    }
    .ten.columns.result-item-wrapper {
        width: 100%;
    }
    .paginate-direction-text {
        display: none;
    }
    .paginate-direction img {
        vertical-align: middle;
        width: 40px;
    }
    .pagination-section.left {
        width: 25%;
    }
    .pagination-section.right {
        width: 25%;
    }
    .pagination-section.middle {
        width: 50%;
    }
}
@media (min-width: 550px) {
    .hits-desc {
        display: inline;
    }
    .ten.columns.result-item-wrapper {
        width: 100%;
    }
    .paginate-direction-text {
        display: inline-block;
    }
    .paginate-direction img {
        vertical-align: middle;
        width: 30px;
        margin-top: -2px;
    }
}
@media (max-width: 749px) {
    .resultlist {
        width: 100%;
    }
    .result-item-details {
        width: 100%;
    }
}
@media (max-width: 1023px) {
    .result-item-image-wrap {
        display: none;
    }
}
@media (min-width: 1024px) {
    .ten.columns.result-item-wrapper {
        width: 82.6666666667%;
    }
}
.results-header {
    width: 100%;
}
.v-spacer {
    margin-top: 2%;
}
.resultlist-overlay {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}
#results {
    width: 100%;
}
.sk-cube-grid {
    width: 80px;
    height: 80px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}
.current-page {
    font-size: xx-large;
    font-weight: bold;
}
.paginate-direction {
    font-weight: bold;
    text-decoration: none;
    font-size: large;
    line-height: 40px;
}
.pagination-section {
    width: 32.5%;
}
.pagination-section.left {
    text-align: left;
}
.pagination-section.right {
    text-align: right;
}
.pagination-section.middle {
    margin-top: 8px;
}
.paginate-direction {
    color: black;
}
.resultlist-content {
    margin-bottom: 2%;
}
.realestate-info {
    text-align: right;
    display: inline-block;
    max-width: 85px;
    float: right;
    line-height: 1.1;
}
.realestate-info__suffix {
    color: #adadad;
    text-align: left;
    margin-left: 5px;
    float: right;
    width: 20px;
}
.realestate-info__value {
    color: black;
    font-weight: bold;
    text-align: right;
}
.icon-realestate {
    background-image: url(../img/immo_white.svg);
}
.icon-jobs {
    background-image: url(../img/jobs_white.svg);
}
.realestate-main-info-column {
    display: inline-block;
    max-width: calc(100% - 85px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.4;
}