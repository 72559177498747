.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    background: url(../img/location.svg) no-repeat scroll 7px 7px;
    padding-left: 35px !important;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-appearance: none;
    width: 100%;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 38px;
    width: 99%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 3;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    margin-bottom: 0;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
}