html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
}
.App {
    background-color: #fafafa;
}
#root {
    height: 100%;
    width: 100%;
}
.table-container {
    display: table;
    height: 100%;
    width: 100%;
}
.table-block {
    display: table-row;
    height: 1px;
}
.footer-push {
    height: auto;
}
.container {
    max-width: 800px;
    width: 85vw;
}

.container {
    max-width: 800px;
}
@media (min-width: 400px) {
    .container {
        width: 85vw;
    }
}
@media (max-width: 549px) {
    .container {
        width: 100vw;
        padding: 0 20px;
    }
    .jumble-logo.desktop {
        display: none;
    }
    .home-type-toggle {
        text-align: center;
    }
    .toggle-home-text-placeholder:after {
        content: "Immo";
    }
}
.header {
    margin-top: 6rem;
    text-align: center;
}
@media (min-width: 550px) {
    .container {
        width: 85vw;
    }
    .jumble-logo.desktop {
        display: block;
    }
    .home-type-toggle {
        text-align: right;
    }
    .toggle-home-text-placeholder:after {
        content: "Immobilien";
    }
}
.content {
    max-width: 800px;
    margin-top: 5%;
}
.subheadline {
    float: right;
    font-weight: lighter;
    margin-top: -10px;
    margin-right: -20px;
    font-size: 20px;
}
.footer {
    text-align: center;
    line-height: 20px;
}
.footer ul li + li {
    padding-left: 3%;
}
.footer ul li {
    display: inline-block;
    line-height: 30px;
}
.footer ul li a {
    color: #777;
    text-decoration: none;
}
.footer ul li a:hover {
    color: black;
}
.nav-item {
    color: darkgrey;
}
.nav-item.active > a {
    color: black;
    font-weight: bold;
}
label {
    color: #6c6c6c;
    margin-top: .5rem;
}
input[type="email"], input[type="number"], input[type="search"], input[type="submit"], input[type="text"],
input[type="tel"], input[type="url"], input[type="password"], textarea, select, button {
    border: 1px solid #ADADAD;
    -webkit-appearance: none;
}
.main-logo-wrapper {
    text-align: center;
}
.main-logo {
    display: inline-block;
    max-width: 350px;
}
.app-header {
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-top: 1px;
    margin-bottom: 40px;
    background-color: #1565c0;
}
h1 {
    overflow-wrap: break-word;
}
.alphabetic-list {
    text-align: center;
    overflow-wrap: break-word;
}
.alphabetic-list li {
    display: inline;
    font-size: 20px;
    line-height: 40px;
    padding-right: 7px;
}
.alphabetic-list-item-selected {
    font-weight: bold;
    color: black;
    font-size: 30px;
}
.home-section {
    /*-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    /*-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    border: 1px solid lightgray;
    padding: 20px;
    /*border-radius: 10px;*/
    text-align: center;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    color: lightslategray;
    background-color: white;
}
.vertical-center {
    display: table-cell;
    vertical-align: middle;
}
.section-icon {
    vertical-align: bottom;
    background-repeat: no-repeat;
    background-size: 36px 36px;
    background-position: center;
    width: 36px;
    height: 36px;
    display: inline-block;
}
.home-icon-jobs {
    background-image: url(../img/jobs.svg);
}
.home-icon-jobs-inactive {
    background-image: url(../img/jobs_light.svg);
}
.home-icon-immo {
    background-image: url(../img/immo.svg);
}
.home-icon-immo-inactive {
    background-image: url(../img/immo_light.svg);
}
.toggle-home:first-child {
    margin-right: 30px;
}
.toggle-home {
    height: 40px;
    display: inline-block;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.38);
}
.toggle-home.selected {
    color: black;
}
.toggle-home.selected .toggle-home-text {
    /*text-decoration: underline;*/
}
.toggle-home-text {
    font-weight: bold;
    height: 20px;
}
.toggle-home:hover {
    background-color: inherit;
    color: black;
    cursor: pointer;
}
.toggle-home:hover .home-icon-immo-inactive {
    background-image: url(../img/immo.svg);
}
.toggle-home:hover .home-icon-jobs-inactive {
    background-image: url(../img/jobs.svg);
}
.toggle-home:hover .toggle-home-text {
    font-weight: bold;
    color: black;
}
.home-search-box {
    padding: 10px 20px;
    /*border: 1px solid lightgrey;*/
    border-radius: 10px;
}
.home-type-toggle {
    margin: -20px 10px 15px 10px;
}
.toggle-home-text {
    padding-top: 5px;
    display: inline-block;
    font-family: "Avenir Next", "Helvetica", "Arial", sans-serif;
    font-size: 24px;
}
.jumble-misc-footer {
    background-color: white;
    border-top: 1px solid lightgray;
    display: table-cell;
}
.jumble-misc-footer > .container > .footer {
    margin-top: 30px;
}
tr:last-child td {
    border-bottom: none;
}
.jumble-logo {
    color: white;
    font-family: "Avenir Next", "Helvetica", "Arial", sans-serif;
    font-size: 38px;
    text-decoration: none;
}
.jumble-logo:hover {
    color: white;
    text-decoration: none;
}
.jumble-logo-section {
    font-style: italic;
    display: inline-block;
    font-weight: bold;
}
.jumble-logo.mobile {
    float: none;
    margin: 5px;
    display: inline-block;
    font-size: 26px;
}
.beta-badge {
    vertical-align: 14px;
    font-size: 16px;
}
.home {
    height: 330px;
}